import React, { useState } from 'react'
import {ref, uploadBytesResumable} from "@firebase/storage"
import { storage, db } from './firebase'
import { v4 as uuidv4 } from 'uuid'

import Button from '@mui/material/Button'
import logo from './header-image-1.jpg'

import './App.css';
import { getDownloadURL, uploadBytes } from 'firebase/storage'
import { FormControl } from '@mui/material'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

function App() {

  const INITIAL_DATA = {
    name: "",
    description:"",
  }

  const[progress, setProgress] = useState()
  const[form, setForm] = useState({...INITIAL_DATA})
  const [errorFileMessage, setErrorFileMessage] = React.useState("");

  const formHandler = (e) => {
    e.preventDefault();
    const file = e.target[3].files[0];
    if (!file) {
      setErrorFileMessage("Please select a file");
      return;
    };
    setErrorFileMessage("");
    saveToDb(file);
    uploadFiles(file, e);
  }

  const saveToDb = async (file) => {
    console.log('need to save to db'+ form.name );
    try {
      await addDoc(collection(db, 'files'), {
        name: form.name,
        description: form.description,
        created: Timestamp.now(), 
        filename: file.name
      })
    } catch (err) {
      alert(err)
    }
    setForm({ ...INITIAL_DATA });
  }
 
  const uploadFiles = (file, e) => {
    const uniqueFileName = (uuidv4() + "-" + file.name);
    const storageRef = ref(storage, uniqueFileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed", (snapshot) =>{
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );

      setProgress(prog + ' %');
    }, (err) => console.log(err),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(url => console.log(url));
      e.target[3].value = null;
      setProgress('Done');
    }
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="header-text">
          JUMPTEAM
          <span className="header-tagline">file upload </span>
        </p>
      </header>
      <p>
          Select your file to upload
      </p>
      <form onSubmit={formHandler}>
        <FormControl>
          <TextField  
            id="Name"
            label="Your (instagram) name"
            variant="standard"
            style={{marginBottom:'24px'}}
            name="Name"
            value={form.name}
            onChange={(event) => setForm({...form, name: event.target.value})}
          />
          <TextareaAutosize
            aria-label="empty textarea"
            minRows={3}
            placeholder="Plaats je bericht bij de foto of tag andere mensen"
            style={{marginBottom:'24px'}}
            id="description"
            name="description"
            value={form.description}
            onChange={(event) => setForm({...form, description: event.target.value})}
          />
          <Input 
            type="file" name="files"
          />
          <div style={{color:'red', fontSize:'14px', width:'100%', textAlign:'left'}} >{errorFileMessage}</div>
          <Button type="submit" variant="contained" style={{marginTop:'24px'}} >Upload</Button>
        </FormControl>
      </form>
      {progress && (
        <h3>Upload Status: {progress}</h3>
      )}
      
      
      <p className="copytekst">
        Door het uploaden van bestanden geef je toestemming aan Jumpteam om de geüploade bestanden voor verschillende doeleinden te gebruiken, op te slaan en te dupliceren. 
        De bestanden kunnen onder andere maar niet uitsluitend gebruikt worden voor social media, print-uitingen en overige reclame doeleinden.
        Jumpteam zal deze media niet verkopen aan commerciële partijen.
      </p>
    </div>
  );
}

export default App;
