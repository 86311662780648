import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"
import { initializeAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    // Your Credentials
    apiKey: "AIzaSyAPFVdk-vEHcz5tGRP7f4E9PYq0qbFYG7E",
    authDomain: "jump-upload-342718.firebaseapp.com",
    projectId: "jump-upload-342718",
    storageBucket: "jump-upload-342718.appspot.com",
    messagingSenderId: "976075118916",
    appId: "1:976075118916:web:436ae6fef78868804c1f11",
    measurementId: "G-6S1XVSS8LF"
  };

export const app = initializeApp(firebaseConfig)
export const storage = getStorage()
export const db = getFirestore(app)
initializeAnalytics(app);